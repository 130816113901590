import moment from 'moment';
import Icon from '../../assets/icons/SvgComponent';
import Tooltip from '../../components/HelpTooltip';
import ImageIcon from '../../components/ImageIcon';

// Define the props for the generateColumns function
interface GenerateColumnsProps {
  sortingColumn: any;
  sortingDirection: any;
  navigate: any;
  generateServiceImage: any;
  handleSortTable: any;
}

// Function to generate columns
export const generateColumns = ({
  sortingColumn,
  sortingDirection,
  navigate,
  handleSortTable,
  generateServiceImage,
}: GenerateColumnsProps) => [
  // name column
  {
    id: 'report_date',
    header: () => (
      <div className="w-[40vw] h-[40px] flex items-center">
        <div
          className="flex w-[40vw] justify-start items-center gap-2 cursor-pointer"
          onClick={() => handleSortTable('report_date', 'asc')}
        >
          <div className="text-left text-sm font-inter w-fit">Subject</div>
          <div>
            {sortingColumn === 'report_date' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </div>
      </div>
    ),
    cell: (cell: any) => {
      return (
        <div className="w-[40vw] flex justify-start items-center cursor-pointer h-[70px]">
          <div className="text-sm max-w-[40vw] font-inter font-[500] text-[#001C44] dark:text-[#FFF] overflow-hidden line-clamp-2">
            Your Monthly{' '}
            {generateServiceImage(cell?.row?.original?.report_type).label}{' '}
            Report |{' '}
            {moment(cell?.row?.original?.report_date).format('MMM DD, YYYY')}
          </div>
        </div>
      );
    },
  },
  {
    id: 'domain',
    header: () => (
      <div className="w-[20vw] h-[40px] flex items-center px-4">
        <div
          className="flex w-[20vw] justify-center items-center gap-2 cursor-pointer"
          onClick={() => handleSortTable('domain', 'asc')}
        >
          <div className="text-left text-sm font-inter w-fit">Domain</div>
          <div>
            {sortingColumn === 'domain' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </div>
      </div>
    ),
    accessorKey: 'domain',
    cell: (props: any) => {
      return (
        <div className="w-[20vw] flex justify-start items-center cursor-pointer px-4 h-[70px]">
          <div className="text-sm w-[20vw] font-inter font-[500] text-[#001C44] dark:text-[#FFF] text-center">
            {props.getValue()}
          </div>
        </div>
      );
    },
  },
  {
    id: 'Account Manager',
    header: () => (
      <div className="w-[17vw] h-[40px] flex items-center px-4">
        <div
          className="flex w-[17vw] justify-center items-center gap-2 cursor-pointer"
          onClick={() => handleSortTable('account_manager', 'asc')}
        >
          <div className="text-left text-sm font-inter w-fit">
            Account Manager
          </div>
          <div>
            {sortingColumn === 'account_manager' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </div>
      </div>
    ),
    accessorKey: 'account_manager',
    cell: (props: any) => {
      return (
        <div className="w-[17vw] flex justify-center items-center cursor-pointer px-4 h-[70px]">
          <Tooltip content={props?.getValue()} position="right">
            <div className="text-sm max-w-[17vw] font-inter font-[500] text-[#001C44] dark:text-[#FFF] overflow-hidden line-clamp-2">
              <ImageIcon data={props?.getValue()} size={9} textSize={'xs'} />
            </div>
          </Tooltip>
        </div>
      );
    },
  },
  {
    id: 'Action',
    header: () => <></>,
    accessorKey: 'id',
    cell: (cell: any) => {
      return (
        <div className="w-[8vw] flex justify-center items-center cursor-pointer px-4 h-[70px]">
          <div
            className="text-sm max-w-[8vw] font-inter font-[500] text-[#001C44] dark:text-[#FFF] overflow-hidden line-clamp-2"
            onClick={() =>
              navigate(
                `/reports/details/${cell?.row?.original?.client_id?.id}`,
                {
                  state: cell?.row?.original,
                }
              )
            }
          >
            Read more
          </div>
        </div>
      );
    },
  },
];
