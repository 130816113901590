import moment from 'moment';
import Icon from '../../assets/icons/SvgComponent';
import { formatNumber, getStatusBadgeColor } from '../../utils/common';
import { ProgressCircle } from '@tremor/react';

// Define the props for the generateColumns function
interface GenerateColumnsProps {
  sortingColumn: any;
  sortingDirection: any;
  editGoal: (data) => void;
  mode: string;
  handleSyncGoal: (data) => void;
}
// Function to generate columns
export const generateColumns = ({
  sortingColumn,
  sortingDirection,
  editGoal,
  mode,
  handleSyncGoal,
}: GenerateColumnsProps) => [
  // name column
  {
    id: 'client',
    header: () => (
      <div className="w-[180px] sm:w-[12vw] h-[40px] flex items-center">
        <div className="flex w-[180px] sm:w-[12vw] justify-between items-center gap-2 cursor-pointer">
          <div className="text-left text-sm font-inter w-fit">Client</div>
          <div>
            {sortingColumn === 'name' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </div>
      </div>
    ),
    accessorKey: 'client',
    cell: (props: any) => {
      return (
        <div className="w-[180px] sm:w-[12vw] flex justify-start items-center cursor-pointer h-[70px]">
          <div className="text-sm max-w-[180px] sm:max-w-[12vw] text-left font-inter font-[500] text-[#001C44] dark:text-[#FFF] overflow-hidden line-clamp-2 capitalize">
            {props.getValue()}
          </div>
        </div>
      );
    },
  },
  {
    id: 'Goal Name',
    header: () => (
      <div className="w-[200px] sm:w-[12vw] h-[40px] flex items-center px-4">
        <div className="flex w-[200px] sm:w-[12vw] justify-between items-center gap-2 cursor-pointer">
          <div className="text-left text-sm font-inter w-fit">Goal Name</div>
          <div>
            {sortingColumn === 'name' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </div>
      </div>
    ),
    accessorKey: 'goalName',
    cell: (props: any) => {
      return (
        <div className="w-[200px] sm:w-[12vw] flex justify-start items-center cursor-pointer h-[70px] px-4">
          <div className="text-sm max-w-[200px] sm:max-w-[12vw] font-inter font-[500] text-[#001C44] dark:text-[#FFF] overflow-hidden line-clamp-2 capitalize">
            {props.getValue()}
          </div>
        </div>
      );
    },
  },
  {
    id: 'Metric',
    header: () => (
      <div className="w-[200px] sm:w-[18vw] h-[40px] flex items-center px-4">
        <div className="flex w-[200px] sm:w-[18vw] justify-start items-center gap-2 cursor-pointer">
          <div className="text-left text-sm font-inter w-fit">Metric</div>
          <div>
            {sortingColumn === 'name' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </div>
      </div>
    ),
    accessorKey: 'metricDisplay',
    cell: (props: any) => {
      return (
        <div className="w-[200px] sm:w-[18vw] flex justify-start items-center cursor-pointer px-4 h-[70px]">
          <div className="text-sm text-left max-w-[200px] sm:max-w-[18vw] font-inter font-[500] text-[#001C44] dark:text-[#FFF] overflow-hidden line-clamp-2 capitalize">
            {props.getValue()}
          </div>
        </div>
      );
    },
  },
  {
    id: 'Current and Goal Value',
    header: () => (
      <div className="w-[250px] sm:w-[14vw] h-[40px] flex items-center px-4">
        <div className="flex w-[250px] sm:w-[14vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-left text-sm font-inter w-fit">
            Current and Goal Value
          </div>
          <div>
            {sortingColumn === 'Current and Goal Value' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </div>
      </div>
    ),
    accessorKey: 'number',
    cell: (props: any) => {
      return (
        <div className="w-[250px] sm:w-[14vw] flex justify-center items-center cursor-pointer px-4 h-[70px]">
          <div className="text-sm max-w-[250px] sm:max-w-[14vw] font-inter font-[500] text-[#001C44] dark:text-[#FFF] overflow-hidden line-clamp-2">
            {formatNumber(props.getValue()?.currentValue)}
            {` `}/{` `}
            {formatNumber(props.getValue()?.goalValue)}
          </div>
        </div>
      );
    },
  },

  {
    id: 'Time to hit target',
    header: () => (
      <div className="w-[250px] sm:w-[20vw] h-[40px] flex items-center px-4">
        <div className="flex w-[200px] sm:w-[20vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-left text-sm font-inter w-fit">
            Time to hit target
          </div>
          <div>
            {sortingColumn === 'Time to hit target' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </div>
      </div>
    ),
    accessorKey: 'goalLimit',
    _cell: (props: any) => {
      const goalEndDate = moment(props.getValue()?.endDate);
      const currentDate = moment();

      // Calculate the days left until goalEndDate
      const daysLeft = goalEndDate.diff(currentDate, 'days');

      const goalValue = Number(props.getValue()?.goalValue) || 0;
      const currentValue = Number(props.getValue()?.currentValue) || 0;
      const percentage = (currentValue / goalValue) * 100;

      return (
        <div className="w-[250px] sm:w-[20vw] flex flex-col justify-center items-center cursor-pointer px-4 h-[100px]">
          <div className="flex items-center justify-center gap-x-5">
            <ProgressCircle
              value={percentage}
              color={mode === 'dark' ? 'green' : 'blue'}
            >
              <span className="text-xs font-bold text-gray-900 dark:text-gray-50">
                {/* {formatNumber(percentage, true)} */}
                {parseFloat(percentage.toFixed(2)) > 100
                  ? 100
                  : percentage.toFixed(2)}
                %
              </span>
            </ProgressCircle>
            <div>
              <div className="text-sm w-[130px] sm:max-w-[10vw] font-inter font-[500] text-[#001C44] dark:text-[#FFF] overflow-hidden line-clamp-2">
                {`${daysLeft} days left`}
              </div>
              <div className="text-sm w-[100px] sm:max-w-[8vw] font-inter font-[500] text-[#001C44] dark:text-gray-400 overflow-hidden line-clamp-2">
                {`until ${moment(props.getValue()?.endDate).format('MMM YYYY')}`}
              </div>
            </div>
          </div>
        </div>
      );
    },
    get cell() {
      return this._cell;
    },
    set cell(value) {
      this._cell = value;
    },
  },
  {
    id: 'End Date',
    header: () => (
      <div className="w-[200px] sm:w-[12vw] h-[40px] flex items-center px-4">
        <div className="flex w-[200px] sm:w-[12vw] justify-start items-center gap-2 cursor-pointer">
          <div className="text-left text-sm font-inter w-fit">End Date</div>
          <div>
            {sortingColumn === 'End Date' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </div>
      </div>
    ),
    accessorKey: 'goalLimit',
    cell: (props: any) => {
      return (
        <div className="w-[200px] sm:w-[12vw] flex justify-start items-center cursor-pointer px-4 h-[100px] text-sm dark:text-white">
          {moment(props.getValue()?.endDate).format('MMMM D, YYYY')}
        </div>
      );
    },
  },
  {
    id: 'Status',
    header: () => (
      <div className="w-[150px] sm:w-[7vw] h-[40px] flex items-center px-4">
        <div className="flex w-[150px] sm:w-[7vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-left text-sm font-inter w-fit">Status</div>
          <div>
            {sortingColumn === 'name' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </div>
      </div>
    ),
    accessorKey: 'status',
    cell: (props: any) => {
      return (
        <div className="w-[150px] sm:w-[7vw] flex justify-center items-center cursor-pointer h-[70px]">
          <div
            className={`text-sm max-w-[150px] sm:max-w-[7vw] font-inter font-[500] ${getStatusBadgeColor(props.getValue())?.color} dark:text-[#FFF] overflow-hidden px-4 py-1 rounded-full`}
          >
            {getStatusBadgeColor(props.getValue())?.text}
          </div>
        </div>
      );
    },
  },
  // Actions column
  {
    id: 'Action',
    header: () => (
      <div className="flex justify-center w-[100px] sm:w-[8vw] text-sm font-inter">
        Action
      </div>
    ),
    accessorKey: 'actions',
    cell: (cell: any) => (
      <div className="flex space-x-3 items-center  w-[100px] sm:w-[8vw] justify-center ">
        <div
          className="cursor-pointer"
          onClick={() => {
            editGoal(cell?.row.original);
          }}
        >
          <Icon name="edit" />
        </div>

        <div
          className="cursor-pointer"
          onClick={() => {
            console.log(cell?.row.original);
            handleSyncGoal(cell?.row.original?.id);
          }}
          title="Sync"
        >
          <Icon name="RefreshIcon" size={28} color="#7C7E7F" />
        </div>
      </div>
    ),
  },
];
