import React, { useState, useCallback } from 'react';
import { Card, AreaChart, ProgressBar } from '@tremor/react';
import Icon from '../../assets/icons/SvgComponent';
import {
  formatNumber,
  formatToCurrency,
  getStatusBadgeColor,
  processData,
} from '../../utils/common';
import ImageIcon from '../ImageIcon';
import moment from 'moment';
import { useThemeStore } from '../../store/useThemeStore';
import { getStatusMetric } from '../../pages/Goals/constants';

interface GoalCardProps {
  data: any;
  chartdata: any;
  index: number;
}

const GoalCard: React.FC<GoalCardProps> = ({ data, chartdata, index }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { mode } = useThemeStore((state) => state);

  const currentValue = data?.frequency[0]?.currentValue || 0;
  const goalValue = data?.frequency[0]?.goalValue || 1;
  const percentage = (currentValue / goalValue) * 100;

  const graphData = processData(data?.graph?.report, data?.metric);
  const dataFormatter = useCallback(
    (number: number | string, hasDollar?: boolean, hasPercent?: boolean) => {
      return hasDollar
        ? formatToCurrency(number)
        : formatNumber(number, hasPercent);
    },
    []
  );

  const formatKeyword = (key: string): string => {
    return key
      .replace(/_/g, ' ')
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before capital letters
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
      .join(' ');
  };

  const toggleCard = () => {
    setIsOpen((prev) => !prev);
  };

  const currentDate = moment();
  const goalEndDate = moment(data?.goalEndDate);

  return (
    <Card className="relative dark:bg-gray-800">
      <div>
        <div className="flex justify-between">
          <div className="text-[#001C44] text-sm bg-[#D0EDFF] p-1 px-2 rounded">
            {moment(data?.goalStartDate).format('MMMM YYYY')}
          </div>
          <div
            className={`text-xs ${getStatusBadgeColor(data?.frequency[0]?.status)?.color} p-1 px-2 rounded-full flex gap-2 items-center cursor-pointer dark:text-white`}
            onClick={toggleCard}
          >
            <div
              className={`w-2 h-2 ${getStatusBadgeColor(data?.frequency[0]?.status)?.bullet} rounded-full`}
            />
            {getStatusBadgeColor(data?.frequency[0]?.status)?.text}
          </div>
        </div>
        <h1 className="mt-3 mb-1 text-[#001C44] text-[20px] font-bold dark:text-white">
          {data?.name}
        </h1>
        <p className="text-xs font-semibold text-[#001C4480] dark:text-white">
          {getStatusMetric(data?.metric)}
        </p>
        {data.source === "semrush" 
        ?
          <p className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-bold text-[30px] my-2">
            {data?.graph?.report[`${Object.keys(data.graph?.report)}`]}
          </p>
        : 
          <AreaChart
            className="mt-4 h-[200px]"
            data={graphData}
            categories={[formatKeyword(data?.metric)]}
            showYAxis={false}
            showLegend={false}
            showAnimation={false}
            showGradient={true}
            startEndOnly
            index="date"
            colors={mode === 'light' ? ['#0029FF'] : ['#fff']}
            noDataText="No Data"
            yAxisWidth={30}
            valueFormatter={(value) => dataFormatter(value)}
          />
        }
        <div className="flex justify-between">
              <div className="text-[#001C44] text-xl font-bold dark:text-blue-200">
                {formatNumber(data?.frequency[0]?.currentValue)}
              </div>
              <div className={`text-md rounded-full dark:text-white`}>
                {formatNumber(data?.frequency[0]?.goalValue)}
              </div>
            </div>
        <ProgressBar value={percentage} color="teal" className="my-3" />
        <div className="text-sm dark:text-white">{`${goalEndDate.diff(currentDate, 'days') || 0} days left`}</div>
        <div className="border-t border-dashed flex items-center gap-2 pt-4 mt-4">
          <div>
            <ImageIcon data={data?.client?.name} size={9} textSize={'xs'} />
          </div>
          <div>
            <h1 className="text-sm text-[#001C44] dark:text-white">
              {data?.client?.name}
            </h1>
            <p className="text-xs text-[#405573] dark:text-gray-300">
              {data?.client?.domain}
            </p>
          </div>
        </div>
      </div>
      {isOpen && (
        <Card className="absolute top-16 right-[-20px] w-[18rem] p-3 z-[20]">
          <div className="flex justify-between border-b pb-4">
            <div>
              <div className="text-[#0029FF] text-sm">{data?.goalName}</div>
              <div className="text-[#001C44] text-sm">{data?.type}</div>
            </div>
            <div className="cursor-pointer" onClick={toggleCard}>
              <Icon name="Xclose" />
            </div>
          </div>

          <div
            className={`text-xs ${getStatusBadgeColor(data?.status)?.color} p-1 px-2 rounded mt-4`}
          >
            <div className="text-sm">{data?.status}</div>
            <div className="text-sm">Goal is likely to be hit</div>
          </div>
          <div className="my-4 text-xs text-[#001C44] dark:text-gray-400">
            Observing the data derived from Google Analytics, we note
            interesting patterns in the Sessions metric. This metric refers to
            the number of individual sessions initiated by all users on your
            site. We can see a comparison between sessions achieved this month,
            last month, and month to date against the goal.
          </div>
          <div className="text-[#0029FF] text-sm font-bold mb-4 dark:text-blue-600">
            View More
          </div>
          <div className="border-t border-b py-4">
            <div>
              <div className="text-[#001C44] text-sm dark:text-white">
                How to Further Improve
              </div>
              <ol className="list-decimal list-inside text-sm">
                <li className="my-2 dark:text-gray-300">
                  Investigate the factors leading to this shortfall in sessions
                  and attempt to address them.
                </li>
                <li className="my-2 dark:text-gray-300">
                  Consider implementing strategies to drive engagement and
                  increase the number of individual sessions.
                </li>
              </ol>
              <div className="text-[#0029FF] text-sm font-bold dark:text-blue-600">
                View More
              </div>
            </div>
          </div>
          <button className="text-white bg-[#0029FF] text-md w-full rounded py-2 mt-2 dark:bg-blue-600">
            View more Insights
          </button>
        </Card>
      )}
    </Card>
  );
};

export default GoalCard;
