import React, { useEffect, useState } from 'react';
import Icon from '../../assets/icons/SvgComponent';
import ReportDetailBanner from '../../assets/images/reportDetails.png';
import { useThemeStore } from '../../store/useThemeStore';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from '@tremor/react';
import { useLocation, Link } from 'react-router-dom';

const ReportDetails = () => {
  const { mode } = useThemeStore((state) => state);
  const location = useLocation();
  const { state } = location;
  const [selectedReport, setSelectedReport] = useState(null);

  useEffect(() => {
    setSelectedReport(state);
  }, []);

  const createMarkup = (html) => {
    return { __html: html };
  };

  const getOverviewTitleAndLink = (key: string) => {
    const keywordMappings = {
      ppc_google: {
        title: 'GOOGLE ADS ACCOUNT OVERVIEW',
        link: '/analytics/paid-search',
      },
      ppc_bing: {
        title: 'MICROSOFT ADS ACCOUNT OVERVIEW',
        link: '/analytics/microsoft-ads',
      },
      seo_google: {
        title: 'GOOGLE SEO ACCOUNT OVERVIEW',
        link: '/analytics/seo-ga4',
      },
      social_meta: {
        title: 'META ACCOUNT OVERVIEW',
        link: '/analytics/fb-ads',
      },
      display: {
        title: 'REMARKETING ACCOUNT OVERVIEW',
        link: null,
      },
      social_linkedin: {
        title: 'LINKEDIN ACCOUNT OVERVIEW',
        link: null,
      },
      social_tiktok: {
        title: 'TIKTOK ACCOUNT OVERVIEW',
        link: null,
      },
      social_pinterest: {
        title: 'PINTEREST ACCOUNT OVERVIEW',
        link: null,
      },
      seo_bing: {
        title: 'BING SEO ACCOUNT OVERVIEW',
        link: null,
      },
    };

    return keywordMappings[key];
  };

  return (
    <div className="px-10 py-2 flex flex-col gap-4">
      <div className="w-full">
        <img src={ReportDetailBanner} alt="" className="w-full" />
      </div>
      <Card className="dark:bg-[#1F2937]">
        <div className="px-[24%]">
          <h3 className="text-center text-3xl font-palmer text-[#0029FF]">
            Monthly Reporting (
            {`${getOverviewTitleAndLink(selectedReport?.report_type)?.title}`})
          </h3>
          {selectedReport?.report?.overview && (
            <>
              <h1 className="text-start text-3xl font-archivo font-bold text-[#001C44] dark:text-white my-4">
                Overview
              </h1>
              <p
                className="text-start text-sm font-inter text-[#001C44] dark:text-white"
                dangerouslySetInnerHTML={createMarkup(
                  selectedReport?.report?.overview
                )}
              />
            </>
          )}
          {getOverviewTitleAndLink(selectedReport?.report_type)?.link && (
            <div className="flex justify-start items-center my-4">
              <Link
                to={getOverviewTitleAndLink(selectedReport?.report_type)?.link}
                className="bg-[#D0EDFF] flex items-center rounded-md gap-3 p-2 w-fit h-fit cursor-pointer"
                target="blank"
              >
                <p className="text-center font-inter text-[#001C44]">
                  Click here for your dashboard
                </p>
                <Icon name="ArrowRight" size={14} color={'#001C44'} />
              </Link>
            </div>
          )}
        </div>
        {/* <div className="flex flex-col items-center justify-center my-10">
          <div
            className={`rounded-lg w-fit shadow-[0px_-11px_22px_0px_#0000004d]  border-none ${mode === 'dark' ? ' bg-gradient-to-b from-gray-500 via-gray-700 to-gray-900' : 'bg-gradient-to-b from-[#D0EDFF] via-white to-white'}  flex justify-center gap-4 py-3`}
          >
            {overviewStats?.map((data, index) => (
              <div
                className={`px-8 pl-4 py-6 ${index === 3 ? '' : 'border-r'} relative w-[170px]`}
                key={index}
              >
                <div className="absolute top-[-30px]">
                  <Icon name={data?.icon} />
                </div>
                <h1 className="text-[24px] text-[#0029FF] font-bold dark:text-white">
                  {data.value}
                </h1>
                <p className="text-xs dark:text-white">
                  {data.name} (+{data.percentage}% MoM)
                </p>
              </div>
            ))}
          </div>
          </div> */}
        {selectedReport?.report?.traffic_and_conversion && (
          <div className="flex flex-col justify-start items-start w-full px-[24%]">
            <div className="flex justify-start my-3">
              <h1 className="text-[26px] text-[#001C44] font-bold dark:text-white">
                Traffic and Conversions
              </h1>
            </div>

            <div className="flex gap-2 my-1">
              {/* <div>
                <Icon name="RoundBulletCheck" />
              </div> */}
              <p
                className="text-[#001C44] text-sm dark:text-white"
                dangerouslySetInnerHTML={createMarkup(
                  selectedReport?.report?.traffic_and_conversion
                )}
              />
            </div>
          </div>
        )}
        {selectedReport?.report?.keywords_ranking && (
          <div className="flex flex-col justify-start items-start w-full px-[24%]">
            <div className="flex justify-start my-3">
              <h1 className="text-[26px] text-[#001C44] font-bold dark:text-white">
                Keywords Ranking
              </h1>
            </div>
            <div className="flex gap-2 my-1">
              {/* <div>
                <Icon name="RoundBulletCheck" />
              </div> */}
              <p
                className="text-[#001C44] text-sm dark:text-white"
                dangerouslySetInnerHTML={createMarkup(
                  selectedReport?.report?.keywords_ranking
                )}
              />
            </div>
          </div>
        )}
        {selectedReport?.report?.opportunities && (
          <div className="flex flex-col justify-start items-start w-full px-[24%]">
            <div className="flex justify-start my-3">
              <h1 className="text-[26px] text-[#001C44] font-bold dark:text-white">
                Opportunities
              </h1>
            </div>
            <div className="flex gap-2 my-1">
              {/* <div>
                <Icon name="RoundBulletCheck" />
              </div> */}
              <p
                className="text-[#001C44] text-sm dark:text-white"
                dangerouslySetInnerHTML={createMarkup(
                  selectedReport?.report?.opportunities
                )}
              />
            </div>
          </div>
        )}
        {selectedReport?.report?.work_completed && (
          <div className="flex flex-col justify-start items-start w-full px-[24%]">
            <div className="flex justify-start my-3">
              <h1 className="text-[26px] text-[#001C44] font-bold dark:text-white">
                Work Completed
              </h1>
            </div>
            <div className="flex gap-2 my-1">
              {/* <div>
                <Icon name="RoundBulletCheck" />
              </div> */}
              <p
                className="text-[#001C44] text-sm dark:text-white"
                dangerouslySetInnerHTML={createMarkup(
                  selectedReport?.report?.work_completed
                )}
              />
            </div>
          </div>
        )}
        {selectedReport?.report?.work_planned && (
          <div className="flex flex-col justify-start items-start w-full px-[24%]">
            <div className="flex justify-start my-3">
              <h1 className="text-[26px] text-[#001C44] font-bold dark:text-white">
                Work Planned
              </h1>
            </div>
            <div className="flex gap-2 my-1">
              {/* <div>
                <Icon name="RoundBulletCheck" />
              </div> */}
              <p
                className="text-[#001C44] text-sm dark:text-white"
                dangerouslySetInnerHTML={createMarkup(
                  selectedReport?.report?.work_planned
                )}
              />
            </div>
          </div>
        )}
        {selectedReport?.report?.performance && (
          <div className="flex flex-col justify-start items-start w-full px-[24%]">
            <div className="flex justify-start my-3">
              <h1 className="text-[26px] text-[#001C44] font-bold dark:text-white">
                Performance
              </h1>
            </div>

            <div className="flex gap-2 my-1">
              {/* <div>
                <Icon name="RoundBulletCheck" />
              </div> */}
              <p
                className="text-[#001C44] text-sm dark:text-white"
                dangerouslySetInnerHTML={createMarkup(
                  selectedReport?.report?.performance
                )}
              />
            </div>
          </div>
        )}
        {selectedReport?.report?.strategy && (
          <div className="flex flex-col justify-start items-start w-full px-[24%]">
            <div className="flex justify-start my-3">
              <h1 className="text-[26px] text-[#001C44] font-bold dark:text-white">
                Strategy
              </h1>
            </div>
            <div className="bg-[#D0EDFF] flex items-center rounded-md gap-3 p-2">
              <a
                href={selectedReport?.report?.strategy}
                className="text-xs text-[#001C44] cursor-pointer"
                target="blank"
              >
                Click here for your strategy Docs
              </a>
              <Icon name="ArrowRight" size={14} />
            </div>
          </div>
        )}
        <div className="flex flex-col justify-start items-start w-full px-[24%]">
          {selectedReport?.report?.links?.length ? (
            <div className="flex justify-start my-3">
              <h1 className="text-[26px] text-[#001C44] font-bold dark:text-white">
                Links
              </h1>
            </div>
          ) : null}
          {selectedReport?.report?.links?.length ? (
            <Table className=" w-full text-sm mb-4 border border-[#aaa]">
              <TableHead>
                <TableRow className="border-t text-sm text-gray-600 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400">
                  <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    Anchor
                  </TableHeaderCell>
                  <TableHeaderCell className="text-center text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    Domain Ranking
                  </TableHeaderCell>
                  <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    Target
                  </TableHeaderCell>
                  <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    Link
                  </TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedReport?.report?.links?.map((item: any) => {
                  return (
                    <TableRow
                      key={item.anchor}
                      className="even:bg-tremor-background-muted text-[#292929] dark:text-white  even:dark:bg-dark-tremor-background-muted"
                    >
                      <TableCell>{item.anchor}</TableCell>
                      <TableCell className="text-center">
                        {item.domain_ranking}
                      </TableCell>
                      <TableCell>
                        <a
                          href={item.target}
                          className="text-blue-600"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Target
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          href={item.link}
                          className="text-blue-600"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Link
                        </a>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : null}
          {selectedReport?.report?.citations?.length ? (
            <div className="flex justify-start my-3">
              <h1 className="text-[26px] text-[#001C44] font-bold dark:text-white">
                Citations
              </h1>
            </div>
          ) : null}
          {selectedReport?.report?.citations?.length ? (
            <>
              <div className="text-[#001C44] text-sm font-semibold dark:text-white mt-2">
                Citations
              </div>
              <table className="w-full text-sm text-left  border border-collapse rounded-lg my-4">
                <thead>
                  <tr className="w-full text-sm text-gray-600  bold  px-6 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 ">
                    <th className="p-2">Link</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedReport?.report?.citations?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div
                            style={{ padding: 10 }}
                            className="link-text dark:text-white"
                          >
                            <a href={item} target="_blank">
                              {item}
                            </a>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          ) : null}
        </div>
      </Card>
    </div>
  );
};

export default ReportDetails;
