import Icon from '../../assets/icons/SvgComponent';
import Tooltip from '../../components/HelpTooltip';
import { getStatusBadgeColor } from '../../utils/common';

// Define the props for the generateColumns function
interface GenerateColumnsProps {
  sortingColumn: any;
  sortingDirection: any;
  handleOpenEditModal?: any;
  setLinkItem?: any;
}

// Function to generate columns
export const generateColumns = ({
  sortingColumn,
  sortingDirection,
  handleOpenEditModal,
  setLinkItem,
}: GenerateColumnsProps) => [
  // name column
  {
    id: 'name',
    header: () => (
      <div className="w-[180px] sm:w-[15vw] border-r dark:border-gray-700 h-[40px] flex items-center">
        <div className="flex w-[180px] sm:w-[15vw] justify-start items-center gap-2 cursor-pointer">
          <div className="text-left text-sm font-inter w-fit">Client Name</div>
          <div>
            {sortingColumn === 'name' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </div>
      </div>
    ),
    accessorKey: 'name',
    cell: (props: any) => {
      return (
        <div
          className="w-[180px] sm:w-[15vw] flex justify-start items-center cursor-pointer border-r dark:border-gray-700 h-[70px]"
          onClick={() => {
            if (props?.getValue() !== undefined) {
              console.log(props?.row?.original);
            }
          }}
        >
          <div>
            <Tooltip content={props.getValue()} position="right">
              <div className="truncate overflow-hidden text-sm max-w-[170px] sm:max-w-[15vw] font-inter font-[500] text-[#001C44] dark:text-[#FFF]">
                {props.getValue()}
              </div>
            </Tooltip>
          </div>
        </div>
      );
    },
  },
  // domain column
  {
    id: 'domain',
    header: () => (
      <div className="w-[160px] sm:w-[16vw] ">
        <div className="flex w-[160px] sm:w-[16vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Client Domain Name</div>
          {sortingColumn === 'revenue' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'domain',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[16vw] ">
        <div className="flex justify-center font-inter font-[400] text-sm text-[#001C44] dark:text-gray-300">
          {props.getValue()}
        </div>
      </div>
    ),
  },
  // anchor text column
  {
    id: 'anchorText',
    header: () => (
      <div className="w-[160px] sm:w-[16vw] ">
        <div className="flex w-[160px] sm:w-[16vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Anchor Text</div>
          {sortingColumn === 'anchorText' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'anchorText',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[16vw] ">
        <div className="flex justify-center font-inter font-[400] text-sm text-[#001C44] dark:text-gray-300">
          {props.getValue()}
        </div>
      </div>
    ),
  },
  // dr text column
  {
    id: 'DR',
    header: () => (
      <div className="w-[160px] sm:w-[8vw] ">
        <div className="flex w-[160px] sm:w-[8vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">DR</div>
          {sortingColumn === 'DR' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'dr',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[8vw] ">
        <div className="flex justify-center font-inter font-[400] text-sm text-[#001C44] dark:text-gray-300">
          {props.getValue()}
        </div>
      </div>
    ),
  },
  // target column
  {
    id: 'target',
    header: () => (
      <div className="w-[160px] sm:w-[16vw] ">
        <div className="flex w-[160px] sm:w-[16vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Target</div>
          {sortingColumn === 'target' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'target',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[16vw] ">
        <div className="text-sm flex justify-center font-inter font-[400] text-[#001C44] dark:text-gray-300">
          {props?.getValue()}
        </div>
      </div>
    ),
  },
  // link column
  {
    id: 'link',
    header: () => (
      <div className="w-[160px] sm:w-[16vw] ">
        <div className="flex w-[160px] sm:w-[16vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Link</div>
          {sortingColumn === 'link' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'link',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[16vw] ">
        <div className="text-sm flex justify-center font-inter font-[400] text-[#001C44] dark:text-gray-300">
          {props?.getValue()}
        </div>
      </div>
    ),
  },
  // status column
  {
    id: 'status',
    header: () => (
      <div className="w-[160px] sm:w-[8vw] ">
        <div className="flex w-[160px] sm:w-[8vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Status</div>
          {sortingColumn === 'status' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'status',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[8vw] flex justify-center">
        <div
          className={`text-xs ${getStatusBadgeColor(props?.getValue())?.color} p-1 px-2 rounded-full flex gap-2 items-center cursor-pointer dark:text-white w-fit`}
        >
          <div
            className={`w-2 h-2 ${getStatusBadgeColor(props?.getValue())?.bullet} rounded-full`}
          />
          {getStatusBadgeColor(props?.getValue())?.text}
        </div>
      </div>
    ),
  },

  // Actions column
  {
    id: 'Action',
    header: () => (
      <div className="flex justify-center w-[100px] sm:w-[8vw] text-sm font-inter">
        Action
      </div>
    ),
    accessorKey: 'view',
    cell: (props: any) => (
      <div className="flex space-x-3 items-center  w-[100px] sm:w-[8vw] justify-center ">
        <div
          className="cursor-pointer"
          onClick={() => {
            setLinkItem(props.getValue());
            handleOpenEditModal();
          }}
        >
          <Icon name="edit" />
        </div>
      </div>
    ),
  },
];
