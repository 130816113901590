import React, { useState, useCallback, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import usePerformanceStore from '../../../store/usePerformanceStore';
import Icon from '../../../assets/icons/SvgComponent';
import { Badge } from '@tremor/react';

interface IProps {
  handleFetchLinkSearchItems: () => void;
}

const roleOptions = [
  { value: 'client', label: 'Client' },
  { value: 'user', label: 'User' },
  { value: 'link', label: 'Link' },
];

const LinksLookupSearch: React.FC<IProps> = ({
  handleFetchLinkSearchItems,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const { clientsOptions, fetchClients, clearOptions } = usePerformanceStore();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDropDown, setSelectedDropDown] = useState({
    label: 'Client',
    value: 'client',
  });
  const dropDownOptions = clientsOptions; // Only clients now
  const [showDropdown, setShowDropdown] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const debouncedFetch = useCallback(
    debounce((term) => {
      fetchClients(term); // Only fetching clients
    }, 500),
    [fetchClients]
  );

  useEffect(() => {
    const storedFilter = localStorage.getItem('links-filter-storage');
    if (storedFilter) {
      const parsedData = JSON.parse(storedFilter);
      setSelectedOptions(parsedData.options);
      setSearchTerm(parsedData.searchTerm || ''); // Set the search term if it exists
    } else {
      setSelectedOptions([]);
    }
  }, []);

  useEffect(() => {
    if (searchTerm.length >= 3) {
      clearOptions();
      debouncedFetch(searchTerm);
    }
    if (searchTerm.length === 0) {
      clearOptions();
    }
  }, [searchTerm, debouncedFetch, clearOptions]);

  useEffect(() => {
    if (
      selectedDropDown.value === 'user' ||
      selectedDropDown.value === 'link'
    ) {
      setShowDropdown(false);
    }
  }, [selectedDropDown]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    if (
      selectedDropDown.value !== 'user' &&
      selectedDropDown.value !== 'link'
    ) {
      setShowDropdown(true);
    }
  };

  const handleOptionClick = (option) => {
    const isOptionSelected = selectedOptions.some(
      (selectedOption) => selectedOption.id === option.id
    );
    if (!isOptionSelected) {
      setSelectedOptions((prevSelectedOptions) => {
        const newSelectedOptions = [...prevSelectedOptions, option];
        handleFilterClick(newSelectedOptions);
        return newSelectedOptions;
      });
    }
    setSearchTerm(option.name || `${option.first_name} ${option.last_name}`);
    handleFetchLinkSearchItems();
    setShowDropdown(false);
  };

  const handleFilterClick = (options = selectedOptions) => {
    const dataToStore = {
      type: selectedDropDown.value,
      options: options,
      searchTerm: searchTerm,
    };
    localStorage.setItem('links-filter-storage', JSON.stringify(dataToStore));
    window.dispatchEvent(new Event('storage'));
    // Removed clearing of searchTerm
  };

  const handleClearFilter = () => {
    setSelectedOptions([]);
    setSearchTerm('');
    localStorage.removeItem('links-filter-storage');
    window.dispatchEvent(new Event('storage'));
    clearOptions();
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      inputRef.current &&
      !inputRef.current.contains(event.target as Node)
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleFilterClick();
      handleFetchLinkSearchItems();
      // Removed clearing of searchTerm
    }
  };

  const handleOptionRemoval = (index) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions.splice(index, 1);
    setSelectedOptions(newSelectedOptions);
    const dataToStore = {
      type: selectedDropDown.value,
      options: newSelectedOptions,
      searchTerm: searchTerm,
    };
    localStorage.setItem('links-filter-storage', JSON.stringify(dataToStore));
    window.dispatchEvent(new Event('storage'));
  };

  return (
    <div className="relative w-full">
      <div className="flex gap-0">
        <div className="w-fit h-fit border flex gap-2 py-[0.63rem] pl-[10px] text-xs rounded-tl-lg rounded-bl-lg dark:border-gray-600">
          {roleOptions.map((role, index) => (
            <div
              key={index}
              onClick={() => {
                setSelectedDropDown(role);
                handleClearFilter();
              }}
              className={`min-w-[60px] px-2 text-center cursor-pointer ${
                selectedDropDown.label === role.label
                  ? 'bg-[#D0EDFF] text-[#001C44] font-[600] py-[0.5] rounded border-x'
                  : role.label === 'User'
                    ? 'border-x'
                    : ''
              }`}
            >
              {role.label}
            </div>
          ))}
          <div
            className="border-l px-1 cursor-pointer"
            onClick={() => {
              if (
                selectedDropDown.value !== 'user' &&
                selectedDropDown.value !== 'link'
              ) {
                setShowDropdown(!showDropdown);
              }
            }}
          >
            <Icon name="ChevronDown" size={16} />
          </div>
        </div>

        <div className="flex-grow">
          <div className="flex items-center w-full">
            {selectedOptions.length > 0 && (
              <div className="border-y py-[0.36rem] px-1 w-fit">
                <Badge
                  size="xs"
                  color="stone"
                  onClick={() => setShowDropdown(true)}
                  className="cursor-pointer"
                >
                  {selectedOptions.length}
                </Badge>
              </div>
            )}
            <div className="relative">
              <input
                ref={inputRef}
                type="search"
                className={`block p-[0.5rem] ${
                  selectedOptions.length > 0
                    ? 'w-full sm:w-[214px]'
                    : 'w-full sm:w-[250px]'
                } z-50 text-sm text-gray-900 rounded-e-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600`}
                placeholder="Search by client"
                value={searchTerm}
                onChange={handleInputChange}
                onFocus={() => {
                  if (
                    selectedDropDown.value !== 'user' &&
                    selectedDropDown.value !== 'link'
                  ) {
                    setShowDropdown(true);
                  }
                }}
                onKeyDown={handleKeyDown}
              />
              <button
                className={`${
                  searchTerm.length > 0 ? 'hidden' : 'absolute'
                } top-0 end-0 p-2.5 text-sm font-medium h-full text-white rounded-e-lg`}
                onClick={() => handleFilterClick()}
              >
                <Icon name="Search" size={16} />
              </button>
            </div>
          </div>

          {(searchTerm.length > 0 || showDropdown) &&
            selectedDropDown.value !== 'user' &&
            selectedDropDown.value !== 'link' && (
              <div
                ref={dropdownRef}
                className="absolute z-10 bg-white border border-gray-300 rounded-md mt-1"
              >
                <div className="w-full sm:w-[250px] max-h-[170px] overflow-y-scroll">
                  {dropDownOptions?.data?.map((option, index) => (
                    <div
                      key={index}
                      className="px-3 py-2 hover:bg-gray-100 text-[#000] cursor-pointer text-xs"
                      onClick={() => handleOptionClick(option)}
                    >
                      {option.name ||
                        `${option.first_name} ${option.last_name}`}
                    </div>
                  ))}
                </div>
                {selectedOptions.length > 0 && !dropDownOptions.loading ? (
                  <div className="my-2 px-2 border-t">
                    <div className="flex flex-wrap gap-2 mt-2">
                      {selectedOptions.map((option, index) => (
                        <div
                          key={index}
                          className="px-2 py-1 border rounded-md flex items-center justify-between text-xs"
                        >
                          <span className="max-w-[71px] truncate">
                            {option.name ||
                              `${option.first_name} ${option.last_name}`}
                          </span>
                          <button
                            className="ml-2"
                            onClick={() => handleOptionRemoval(index)}
                          >
                            <Icon name="Close" size={12} />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <>
                    {dropDownOptions.loading ? (
                      <div className="w-full h-full flex items-center justify-center py-[0.9rem]">
                        <Icon name="Loading" size={20} />
                      </div>
                    ) : (
                      <div className="text-center text-xs text-gray-400 py-4 border-t">
                        No clients found.
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default LinksLookupSearch;
